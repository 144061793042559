import {IAuthStateProps} from '../interfaces/IAuthStateProps';
import {IAuthContextProps} from '../interfaces/IAuthContextProps';
import {IUser} from '../interfaces/IUser';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAxios} from '../hooks/useAxios';

export const authContextDefaults: IAuthContextProps = {
    authState: null,
    setAuthInfo: () => null,
    isAuthenticated: () => Promise.resolve(false),
    handleLogout: () => null,
};

export const authStateDefaults: IAuthStateProps = {
    username: null,
    token: null,
    userInfo: null,
};

export const AuthContext =
    React.createContext<IAuthContextProps>(authContextDefaults);

/**
 *  An AuthContext provider that handles authentication.
 */
export default function AuthProvider({
                                         children,
                                     }: React.PropsWithChildren<unknown>) {
    const navigate = useNavigate();
    const axios = useAxios();
    const token = sessionStorage.getItem('token');
    const username = sessionStorage.getItem('username');
    const userInfo = sessionStorage.getItem('userInfo');

    const [authState, setAuthState] = useState<IAuthStateProps>({
        token,
        username,
        userInfo: userInfo ? JSON.parse(userInfo) : null,
    });
    const setAuthInfo = (userInfoData: IUser | null | undefined) => {
        console.log('user', userInfoData)
        if (userInfoData) {
            const {access_token: token, user} = userInfoData;
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('username', user?.name);
            sessionStorage.setItem('userInfo', JSON.stringify(user));
            setAuthState({
                token,
                username,
                userInfo: user,
            });
        }
    };

    const isAuthenticated = async () => {
        const {data} = await axios.post<boolean>('/devtest/api/validate', {});
        return data;
    };

    //Handle Logout context
    const handleLogout = () => {
        setAuthInfo(null);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('userInfo');
        setAuthState(authStateDefaults);
        navigate('/login');
    };
    return (
        <AuthContext.Provider
            value={{
                authState,
                setAuthInfo: (authInfo: IUser | null | undefined) =>
                    setAuthInfo(authInfo),
                isAuthenticated,
                handleLogout,
            }}>
            {children}
        </AuthContext.Provider>
    );
}
