// Utils file for common functions like sort, string operations
export const sortBy = (obj: Array<any>, key: string) => {
  return obj.sort((a, b) => b[key] - a[key]);
};

export const parseErrorMessage = (xmlString: string) => {
  //Example XML string from 500 Error:
  //"<?xml version=\"1.0\" encoding=\"UTF-8\"?>\r\n<Error id=\"401\" message=\"Authorization failed\">\r\n<\/Error>\r\n\r\n"
  let errorId = null;
  let errorMessage = null;
  if (xmlString) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
    const errorElement = xmlDoc.getElementsByTagName('Error')[0];
    errorId = errorElement.getAttribute('id'); //Example: '401'
    errorMessage = errorElement.getAttribute('message'); // Example: 'Authorizaion failed'
  }
  return {
    errorId: errorId,
    errorMessage: errorMessage,
  };
};
