import * as React from 'react';
import {Link, redirect} from 'react-router-dom';
import {Box, Button, Typography} from '@mui/material';
import SessionExpiredImg from '../assets/img/Session-Expired.png';
import {PopupDialog} from '../Components/PopupDialog';

interface ISessionExpiredProps {
    isOpen: boolean;
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SessionExpired: React.FC<ISessionExpiredProps> = ({
                                                            isOpen,
                                                            setDialogOpen,
                                                        }) => {
    // NOTE: This match/nagivate code block is only needed for Mineral's internal CI deploy environment

    React.useEffect(() => {
        setDialogOpen(false);
        redirect('/login');
    }, [setDialogOpen]);

    return (
        <PopupDialog
            isOpen={isOpen}
            content={
                <Box textAlign="center">
                    <img src={SessionExpiredImg} alt="sessionExpired"/>
                    <Typography
                        variant="body1"
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === 'dark' ? '#fff' : '#58606e',
                            fontSize: '1.75rem',
                            fontWeight: 700,
                            lineHeight: 1.25,
                        }}>
                        Session Exprired
                    </Typography>
                    <Typography mt="10px" gutterBottom>
                        Please login again
                    </Typography>
                    <Button
                        variant="contained"
                        // @ts-ignore - Upstream issue with "component" prop in @mineral/core
                        component={Link}
                        to="/login">
                        Sing in
                    </Button>
                </Box>
            }
        />
    );
};

export default SessionExpired;
