import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import AuthProvider from "./context/AuthContext";
import AxiosProvider from "./context/AxiosContext";
import Router from "./router";
import AdProvider from "./context/AdContext";
import { HelmetProvider } from "react-helmet-async";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
const queryClient = new QueryClient();
// @ts-ignore
const App = () => {
  return (
    <AxiosProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <AdProvider>
              <HelmetProvider>
                <Router />
              </HelmetProvider>
            </AdProvider>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </AxiosProvider>
  );
};
// @ts-ignore
export default App;
