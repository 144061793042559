import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  Grid,
  IconButton,
  Breakpoint,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface PopupDialogProps {
  isOpen: boolean;
  content: string | JSX.Element;
  confirmBtnTxt?: string;
  cancelBtnTxt?: string;
  title?: React.ReactNode | string;
  isHtml?: boolean;
  showActionButtons?: boolean;
  onClickYes?: () => void | any;
  onClickNo?: () => void;
  maxWidthValue?: Breakpoint;
  dataTestId?: string;
  hideCancelBtn?: boolean;
}

export const PopupDialog: React.FC<PopupDialogProps> = (props) => {
  const {
    title,
    showActionButtons = false,
    content,
    isOpen,
    confirmBtnTxt,
    cancelBtnTxt,
    isHtml,
    onClickYes,
    onClickNo,
    maxWidthValue = 'xs',
    dataTestId,
    hideCancelBtn,
  } = props;
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          padding: 0,
        },
      }}
      data-testid={dataTestId}
      open={isOpen}
      fullWidth
      maxWidth={maxWidthValue}
      className="popup-dialog">
      {title && (
        <DialogTitle style={{ padding: 8, fontSize: 14, fontWeight: 700 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Grid item>{title}</Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={onClickNo}
                sx={{
                  color: '#fff',
                }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent style={{ padding: '8px 16px' }}>
        {isHtml ? (
          <Box>{content}</Box>
        ) : (
          <DialogContentText>{content}</DialogContentText>
        )}
      </DialogContent>
      {showActionButtons && (
        <DialogActions style={{ padding: '8px 16px' }}>
          {!hideCancelBtn && (
            <Button onClick={onClickNo}>{cancelBtnTxt || 'No'}</Button>
          )}
          <Button onClick={onClickYes}>{confirmBtnTxt || 'Yes'}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
