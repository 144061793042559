import React, { createContext, useMemo, useState } from 'react';
import { AxiosInstance } from 'axios';
import axiosInstance from '../utils/AxiosUtil';
import SessionExpired from '../pages/SessionExpired';
import { parseErrorMessage } from '../utils/WebUtil';

export const AxiosContext = createContext<AxiosInstance>(axiosInstance);

/**
 *  An AxiosContext provider that handles API fetching.
 */
export default function AxiosProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const axios = useMemo(() => {
    axiosInstance.interceptors.request.use(
      // eslint-disable-next-line
      (config: any) => {
        // Read token from sessionStorage
        const token = sessionStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        } else {
          config.headers['Authorization'] = null;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { status, headers, data } = error.response;
        if (
          status === 401 &&
          headers['www-authenticate'] &&
          headers['www-authenticate'].includes('invalid_token')
        ) {
          if (!dialogOpen) {
            setDialogOpen(true);
          }
        } else if (status === 500) {
          const { errorId, errorMessage } = parseErrorMessage(
            data?.error?.message as string
          );
          if (
            errorId === '401' &&
            errorMessage?.toLowerCase() === 'Authorization failed'.toLowerCase()
          ) {
            if (!dialogOpen) {
              setDialogOpen(true);
            }
          }
        }
        return Promise.reject(error);
      }
    );
    return axiosInstance;
  }, []);

  return (
    <AxiosContext.Provider value={axios}>
      {children}
      <SessionExpired isOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </AxiosContext.Provider>
  );
}
