import { AuthContext } from '../context/AuthContext';
import { IAuthContextProps } from '../interfaces/IAuthContextProps';
import { useContext } from 'react';

/**
 * A React hook that exposes user authentication info and functions.
 *
 */
export function useAuth(): IAuthContextProps {
  return useContext(AuthContext);
}
