import { createContext, useEffect, useState } from "react";
import { IAds } from "../interfaces/IAds";
import { useQueryGetAds } from "../api/useQueryGetAds";
import { ISetting } from "../interfaces/ISetting";

export const AdContextDefaults = {
  ads: [],
  setting: {},
};
export const AdContext = createContext<{ ads: IAds[]; setting: any }>(
  AdContextDefaults
);

export default function AdProvider({ children }: any) {
  const [ads, setAds] = useState<IAds[]>([]);
  const [setting, setSetting] = useState<ISetting>();

  const { data: adsData, isLoading, isSuccess } = useQueryGetAds();
  useEffect(() => {
    if (adsData && isSuccess && !isLoading) {
      setAds(adsData.data.ads_data);
      setSetting(adsData.data.setting);
    }
  }, [adsData, isLoading, isSuccess]);

  return (
    <AdContext.Provider value={{ ads: ads, setting: setting }}>
      {children}
    </AdContext.Provider>
  );
}
