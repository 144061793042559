import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

const ProtectedRoute: React.FC<any> = ({ children }) => {
  const { authState } = useAuth();
  const location = useLocation();

  if (!authState?.token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
