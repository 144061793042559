import { Route, Routes } from "react-router-dom";
import React from "react";
import PageLoader from "./Components/PageLoader";
import ProtectedRoute from "./Components/ProtectedRoute";

const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const CMSPage = React.lazy(() => import("./pages/CMSPage"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const CategoryPage = React.lazy(() => import("./pages/CategoryPage"));
const HomePage = React.lazy(() => import("./pages/HomePage"));
const StoryDetailsPage = React.lazy(() => import("./pages/StoryDetailsPage"));
const Signup = React.lazy(() => import("./pages/Signup"));
const Login = React.lazy(() => import("./pages/Login"));
const PostStory = React.lazy(() => import("./pages/PostStory"));
const Profile = React.lazy(() => import("./pages/Profile"));
const MyStories = React.lazy(() => import("./pages/MyStories"));
const Router: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <HomePage />
          </React.Suspense>
        }
      />
      <Route
        path="/category/:slug"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <CategoryPage />
          </React.Suspense>
        }
      />
      <Route
        path="/story/:slug"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <StoryDetailsPage />
          </React.Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <Signup />
          </React.Suspense>
        }
      />
      <Route
        path="/post-story"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProtectedRoute>
              <PostStory />
            </ProtectedRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/profile"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/my-stories"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProtectedRoute>
              <MyStories />
            </ProtectedRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/change-password"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          </React.Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <Login />
          </React.Suspense>
        }
      />
      <Route
        path="/contact-us"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <ContactUs />
          </React.Suspense>
        }
      />
      <Route
        path="/:page"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <CMSPage />
          </React.Suspense>
        }
      />
      <Route
        path="*"
        element={
          <React.Suspense fallback={<PageLoader />}>
            <HomePage />
          </React.Suspense>
        }
      />
    </Routes>
  );
};
export default Router;
