import Logo from "../assets/img/logo.png";

const PageLoader = () => {
  return (
    <div className=" flex w-screen h-screen items-center justify-center">
      <img src={Logo} className="w-60" />
    </div>
  );
};

export default PageLoader;
