import { useQuery } from "react-query";
import { QueryKeys } from "../constants/QueryKeys";
import { useAxios } from "../hooks/useAxios";
import { IAds } from "../interfaces/IAds";
import { ISetting } from "../interfaces/ISetting";
// import { IResourceDataGrid } from 'interfaces/IResourceDataGrid';

export const useQueryGetAds = () => {
  // Get our Axios instance from the Hook
  const axios = useAxios();
  return useQuery(
    // return useQuery<IProjectsResponse, Error>(
    [QueryKeys.ADS],
    async () => {
      // Fetch data from the API using Axios.
      const { data } = await axios.get<
        { ads_data: IAds; setting: ISetting },
        any
      >(`/ads-data`);
      // Return the data from the Axios response
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};
